import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, MenuList, Button, Drawer } from '@mui/material';
import MetaMaskOnboarding from '@metamask/onboarding';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/components/Modal';
import Dialog from '@/components/Dialog';
import { Menu } from '@mui/material';
import ChooseWallet, {
  GetMetaMaskConnectAddressAndProvider,
  GetWalletConnectAddressAndProvider,
} from '@/components/ChooseWallet';
import useDispatchAction from '@/hooks/useDispatchAction';
import {
  handleChooseWallet,
  handleIsMobile,
  handleQuitDialog,
} from '@/store/actions/wallet';
import CheckNet from '@/components/CheckNet';
import LoadingDialog from '@/components/LoadingDialog';
import Web3 from 'web3';
import QuitDialog from '@/components/QuitDialog';
import TedianDialog from '@/components/TedianDialog';
import SignDialog from '@/components/SignDialog';

const Header = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const onboarding = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuShow, setMenuShow] = useState(false);
  const open = Boolean(anchorEl);
  const modal = useSelector((state) => state.wallet.modal);
  const dialog = useSelector((state) => state.wallet.dialog);
  const signdialog = useSelector((state) => state.wallet.signdialog);
  const loadingDialog = useSelector((state) => state.wallet.loading);
  const quitDialog = useSelector((state) => state.wallet.quitDialog);
  const tedianDialog = useSelector((state) => state.wallet.tedianDialog);

  const choice = useSelector((state) => state.wallet.choice);
  const checkNet = useSelector((state) => state.wallet.checkNet);
  const { t } = useTranslation('common');

  const isMobile = useSelector((state) => state.wallet.isMobile);

  const reloadAddressAndProvider = async (walletType) => {
    if (walletType === 'metamask') {
      const { metaMaskProvider, address } =
        await GetMetaMaskConnectAddressAndProvider();
      dispatch(
        handleChooseWallet({
          isOpen: false,
          type: 'metamask',
          baseProvider: metaMaskProvider,
          provider: new Web3(metaMaskProvider),
          address: address,
        }),
      );
    }
    if (walletType === 'walletConnect') {
      const { walletConnectProvider, address } =
        await GetWalletConnectAddressAndProvider();
      dispatch(
        handleChooseWallet({
          isOpen: false,
          baseProvider: walletConnectProvider,
          type: 'walletConnect',
          provider: new Web3(walletConnectProvider),
          address,
        }),
      );
    }
  };
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
      const walletType = sessionStorage.getItem('walletType');
      reloadAddressAndProvider(walletType);
    }
    dispatch(
      handleIsMobile(
        navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/iPhone/i),
      ),
    );
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const MenuItem = ({ children, onClick }) => {
    return (
      <Box
        onClick={onClick}
        sx={{
          height: '100%',
          padding: '0 40px 0 0',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: '16px',
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '75px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'white',
        zIndex: 100,
      }}
    >
      {isMobile ? (
        <>
          <Box
            onClick={() => {
              setMenuShow(true);
            }}
            sx={{
              display: 'flex',
              width: '15%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ width: '50%', height: '30%' }}
              component="img"
              src="/static/menu2.png"
            ></Box>
          </Box>
          <Drawer
            anchor="left"
            open={menuShow}
            onClose={() => setMenuShow(false)}
          >
            <MenuList sx={{ width: '70vw' }}>
              <MenuItem
                sx={{ background: 'black', color: 'white' }}
                onClick={() => {
                  setMenuShow(false);
                  router.push('/');
                }}
              >
                {t('header.home')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuShow(false);
                  router.push('/mint');
                }}
              >
                {t('header.mint')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuShow(false);
                  router.push('/gallery');
                }}
              >
                {t('header.gallery')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuShow(false);
                  router.push('/collection');
                }}
              >
                {t('header.collection')}
              </MenuItem>
            </MenuList>
          </Drawer>
        </>
      ) : (
        <Box sx={{ display: 'none' }}></Box>
      )}
      <Box
        component="img"
        ml={isMobile ? '' : '1.3rem'}
        sx={
          isMobile
            ? {
                width: '50%',
                maxHeight: '70px',
                height: 'auto',
                cursor: 'pointer',
              }
            : {
                width: 'auto',
                height: '32px',
                cursor: 'pointer',
              }
        }
        src="/static/keyvox_logo.jpeg"
        alt="logo"
        onClick={() => {
          setMenuShow(false);
          router.push('/');
        }}
      ></Box>
      {isMobile ? (
        <Box sx={{ maxWidth: '8rem', width: '20%', height: '100%' }}>
          <Box
            onClick={(event) => {
              if (choice.address) {
                dispatch(handleQuitDialog({ isOpen: true }));
              } else {
                dispatch(handleChooseWallet({ isOpen: true }));
              }
            }}
            id="login-btn"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{
              height: '100%',
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#27272a',
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            <Box
              component="img"
              sx={{
                width: '40%',
                height: '40%',
              }}
              src="/static/wallet.png"
              alt="logo"
            ></Box>
          </Box>
        </Box>
      ) : (
        <Box>
          {' '}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MenuItem onClick={() => router.push('/')}>
              {t('header.home')}
            </MenuItem>
            <MenuItem onClick={() => router.push('/mint')}>
              {t('header.mint')}
            </MenuItem>
            <MenuItem onClick={() => router.push('/gallery')}>
              {t('header.gallery')}
            </MenuItem>
            <MenuItem onClick={() => router.push('/collection')}>
              {t('header.collection')}
            </MenuItem>
            <Box
              onClick={(event) => {
                if (choice.address) {
                  dispatch(handleQuitDialog({ isOpen: true }));
                } else {
                  dispatch(handleChooseWallet({ isOpen: true }));
                }
              }}
              id="login-btn"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                height: '75px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 30px',
                background: 'black',
                color: 'white',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '24px',
                  height: '21px',
                  marginRight: '7px',
                }}
                src="/static/wallet.png"
                alt="logo"
              ></Box>
              {choice.address
                ? `${choice.address.slice(0, 4)}...${choice.address.slice(-4)}`
                : t('header.connect')}
            </Box>
          </Box>
        </Box>
      )}

      <Modal
        open={modal.isOpen}
        title={modal.title}
        content={modal.content}
        btnText={modal.btnText}
        jumpUrl={modal.jumpUrl}
      />
      <Dialog
        open={dialog.isOpen}
        totalPrice={dialog.totalPrice}
        type={dialog.type}
        step={dialog.step}
        active={dialog.active}
        errorText={dialog.errorText}
      />
      <SignDialog
        open={signdialog.isOpen}
        totalPrice={signdialog.totalPrice}
        type={signdialog.type}
        step={signdialog.step}
        active={signdialog.active}
        errorText={signdialog.errorText}
      />
      <LoadingDialog
        open={loadingDialog.isOpen}
        totalPrice={loadingDialog.totalPrice}
        type={loadingDialog.type}
        step={loadingDialog.step}
        active={loadingDialog.active}
        errorText={loadingDialog.errorText}
      />
      <QuitDialog
        open={quitDialog.isOpen}
        totalPrice={quitDialog.totalPrice}
        type={quitDialog.type}
        step={quitDialog.step}
        active={quitDialog.active}
        errorText={quitDialog.errorText}
      />
      <TedianDialog
        open={tedianDialog.isOpen}
        totalPrice={tedianDialog.totalPrice}
        type={tedianDialog.type}
        step={tedianDialog.step}
        active={tedianDialog.active}
        errorText={tedianDialog.errorText}
      />
      <ChooseWallet open={choice.isOpen} />
      <CheckNet open={checkNet.isOpen} jumpUrl={checkNet.jumpUrl} />
    </Box>
  );
};

export default Header;
