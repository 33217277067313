import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'next-i18next';
import useDispatchAction from '@/hooks/useDispatchAction';
import { handleModal, initialModal } from '@/store/actions/wallet';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

const Modal = ({ open, title, content, btnText, jumpUrl }) => {
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const { t } = useTranslation('common');
  const router = useRouter();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={() => dispatch(initialModal())}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{ '.MuiDialog-paper': { background: 'transparent' } }}
    >
      <Box
        sx={
          isMobile
            ? {
                minWidth: '300px',
                background: 'white',
                borderRadius: '20px',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
            : {
                width: '600px',
                background: 'white',
                borderRadius: '20px',
                border: '1px solid #707070',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              margin: '30px 0 16px 0',
              fontFamily: 'Gen Shin Gothic P',
              fontSize: isMobile ? '18px' : '30px',
              letterSpacing: '0.06px',
            }}
          >
            {title}
          </Box>
          <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px 0',
            }}
          ></Box>
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'start',
              margin: '10px 0',
            }}
          >
            <Box
              sx={{
                color: '#4C4948',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {content}
            </Box>
          </Box>
        </Box>
        <Box
          component="button"
          sx={{
            margin: '50px 0',
            padding: isMobile ? '10px 20px 1px 2px' : '',
            width: isMobile ? '80%' : '50%',
            height: '48px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
            background: '#5F5F5F',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '28px',
            color: 'white',
          }}
          variant="contained"
          onClick={() => {
            dispatch(
              handleModal({
                title,
                content,
                btnText,
                isOpen: false,
              }),
            );
            if (jumpUrl) {
              router.push(jumpUrl);
            }
          }}
        >
          {btnText}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
