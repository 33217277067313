import '@/styles/globals.scss';
import { wrapper } from '@/store';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import Layout from '@/components/Layout';

function MyApp({ Component, pageProps }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        />
        <meta name="description" content="KEYVOX NFTはKEYVOXサービスが提供する、KEYVOXサービスを使えば使うほどお得になる会員権の機能をを持つNFTです。KEYVOX NFTを保有することで、KEYVOX製品・サービスの無料提供や、サービス運営にかかわる重要な意思決定の投票に参加できるなど、様々な特典を受けられるようになります。" />
        <meta charSet="utf-8" />
        <title>KEYVOX NFT | スマートロック進呈中！</title>
        <link rel="shortcut icon" href="/favicon.png" type="image/png" />
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </>
  );
}

export default wrapper.withRedux(appWithTranslation(MyApp));
