import { Box, Button, Dialog, Skeleton } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleChooseWallet, initialQuitDialog } from '@/store/actions/wallet';
import Web3 from 'web3';
import Header from '@/components/Header';

const QuitDialog = ({ open, step, type, totalPrice, active, errorText }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const choice = useSelector((state) => state.wallet.choice);
  const { t } = useTranslation('common');

  const walletClose = () => {
    const walletType = sessionStorage.getItem('walletType');
    sessionStorage.clear();
    console.log(choice.baseProvider);
    if (choice.baseProvider && walletType === 'walletConnect') {
      choice.baseProvider.disconnect();
    }
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{
        '.MuiDialog-paper': { background: 'transparent' },
      }}
    >
      <Box
        sx={
          isMobile
            ? {
                width: '100%',
                minWidth: '300px',
                background: 'white',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            : {
                width: '400px',
                background: 'white',
                borderRadius: '20px',
                border: '1px solid #707070',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              margin: '30px 0 16px 0',
              fontFamily: 'Gen Shin Gothic P',
              fontSize: isMobile ? '18px' : '30px',
              letterSpacing: '0.6px',
            }}
          >
            {t('quitDialog.title')}
          </Box>
          <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
        </Box>
        <Box
          sx={{
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              padding: '10px 20px  10px 20px',
              borderRadius: '28px',
              marginRight: '40px',
              height: '48px',
            }}
            onClick={() => {
              dispatch(
                initialQuitDialog({
                  isOpen: false,
                }),
              );
            }}
          >
            {t('quitDialog.cancel')}
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: '10px 20px  10px 20px',
              borderRadius: '28px',
              height: '48px',
              marginTop: '30px',
              marginBottom: '30px',
            }}
            onClick={() => {
              walletClose();
            }}
          >
            {t('quitDialog.quit')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default QuitDialog;
