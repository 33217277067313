import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Box from '@mui/material/Box';
import React, { useRef } from 'react';

const Layout = ({ children }) => {
  const ref = useRef(null);
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Header />
      <Box
        ref={ref}
        sx={{
          width: '100%',
          height: 'calc(100% - 70px)',
          overflow: 'auto',
        }}
      >
        {
          // children not Array so React.Children.map is required
          React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return null;
            }
            // add layout ref to child
            const childProps = {
              ...child.props,
              layoutRef: ref,
            };
            return React.cloneElement(child, childProps);
          })
        }
      </Box>
    </Box>
  );
};

export default Layout;
