import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'next-i18next';
import {
  handleChooseWallet,
  initialChooseWallet,
} from '@/store/actions/wallet';
import MetaMaskOnboarding from '@metamask/onboarding';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';

export const GetWalletConnectAddressAndProvider = async () => {
  const walletConnectProvider = new WalletConnectProvider({
    rpc: {
      1: 'https://mainnet.infura.io/v3/',
      137: 'https://polygon-rpc.com',
      100: 'https://dai.poa.network',
      80001: 'https://matic-mumbai.chainstacklabs.com',
      // ...
    },
  });
  let newAccounts;
  try {
    newAccounts = await walletConnectProvider.enable();
  } catch (e) {
    console.log(e);
    return;
  }
  console.log('newAccounts', newAccounts);
  walletConnectProvider.qrcodeModal.close();
  sessionStorage.setItem('walletType', 'walletConnect');
  sessionStorage.setItem('address', newAccounts[0]);
  return {
    walletConnectProvider,
    address: newAccounts[0],
  };
};
export const GetMetaMaskConnectAddressAndProvider = async () => {
  const newAccounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  sessionStorage.setItem('walletType', 'metamask');
  sessionStorage.setItem('address', newAccounts[0]);
  return {
    metaMaskProvider: window.web3.currentProvider,
    address: newAccounts[0],
  };
};

const ChooseWallet = ({ open }) => {
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const onboarding = useRef();
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);
  const handleMetaConnection = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      const { metaMaskProvider, address } =
        await GetMetaMaskConnectAddressAndProvider();
      dispatch(
        handleChooseWallet({
          isOpen: false,
          type: 'metamask',
          baseProvider: metaMaskProvider,
          provider: new Web3(metaMaskProvider),
          address: address,
        }),
      );
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload();
        console.log(accounts);
      });
      window.location.reload();
    } else {
      onboarding.current.startOnboarding();
    }
  };
  const handleWalletConnection = async () => {
    const { walletConnectProvider, address } =
      await GetWalletConnectAddressAndProvider();
    dispatch(
      handleChooseWallet({
        isOpen: false,
        baseProvider: walletConnectProvider,
        type: 'walletConnect',
        provider: new Web3(walletConnectProvider),
        address: address,
      }),
    );
    walletConnectProvider.on('accountsChanged', (accounts) => {
      window.location.reload();
    });
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{ '.MuiDialog-paper': { background: 'transparent' } }}
    >
      <Box
        sx={
          isMobile
            ? {
                minWidth: '300px',
                background: 'white',
                borderRadius: '20px',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
            : {
                width: '500px',
                background: 'white',
                borderRadius: '20px',
                border: '1px solid #707070',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
              marginRight: '30px',
              fontSize: '20px',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(initialChooseWallet());
              // window.location.reload();
            }}
          >
            x
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {' '}
            <Box
              sx={{
                margin: '30px 0  16px 0',
                fontFamily: 'Gen Shin Gothic P',
                fontSize: isMobile ? '18px' : '30px',
                letterSpacing: '0.6px',
              }}
            >
              {t('chooseDialog.title')}
            </Box>
            <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
            <Box
              sx={{
                width: '80%',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '50px 0',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleWalletConnection()}
              >
                <Box
                  component="img"
                  sx={{ width: '70px', height: 'auto' }}
                  src="/static/walletcon.svg"
                />
                <Box sx={{ margin: '15px 0' }}>Wallet Connect</Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: isMobile ? 'none' : 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleMetaConnection()}
              >
                <Box
                  component="img"
                  sx={{ width: '70px', height: 'auto' }}
                  src="/static/metamask.svg"
                />
                <Box sx={{ margin: '15px 0' }}>MetaMask</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ChooseWallet;
