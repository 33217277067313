/**
 * action集合
 */

export const walletActions = {
  INFO: 'INFO',
  LIST: 'LIST',
  MODAL: 'MODAL',
  DIALOG: 'DIALOG',
  SIGNDIALOG:'SIGNDIALOG',
  LOADING: 'LOADING',
  INITIAL_DIALOG: 'INITIAL_DIALOG',
  INITIAL_LOADINGDIALOG: 'INITIAL_LOADINGDIALOG',
  INITIAL_MODAL: 'INITIAL_MODAL',
  INITIAL_CHOOSE_WALLET: 'INITIAL_CHOOSE_WALLET',
  INITIAL_CHECK_NET: 'INITIAL_CHECK_NET',
  QUITDIALOG: 'QUITDIALOG',
  TEDIANDIALOG: 'TEDIANDIALOG',
  CHOOSE_WALLET: 'CHOOSE_WALLET',
  CHECK_NET: 'CHECK_NET',
  IS_MOBILE: 'IS_MOBILE',
  INITIAL_QUITDIALOG: 'INITIAL_QUITDIALOG',
  INITIAL_TEDIANDIALOG: 'INITIAL_TEDIANDIALOG',
};
export function handleModal(payload) {
  return {
    type: walletActions.MODAL,
    payload,
  };
}
export function handleLoadingDialog(payload) {
  return {
    type: walletActions.LOADING,
    payload,
  };
}
export function handleQuitDialog(payload) {
  return {
    type: walletActions.QUITDIALOG,
    payload,
  };
}
export function handleDialog(payload) {
  return {
    type: walletActions.DIALOG,
    payload,
  };
}
export function handleSignDialog(payload) {
  return {
    type: walletActions.SIGNDIALOG,
    payload,
  };
}


export function handleChooseWallet(payload) {
  return {
    type: walletActions.CHOOSE_WALLET,
    payload,
  };
}
export function handleCheckNet(payload) {
  return {
    type: walletActions.CHECK_NET,
    payload,
  };
}
export function handleIsMobile(payload) {
  return {
    type: walletActions.IS_MOBILE,
    payload,
  };
}
export function handleTedianDialog(payload) {
  return {
    type: walletActions.TEDIANDIALOG,
    payload,
  };
}
export function initialDialog() {
  return {
    type: walletActions.INITIAL_DIALOG,
  };
}
export function initialModal() {
  return {
    type: walletActions.INITIAL_MODAL,
  };
}
export function initialChooseWallet() {
  return {
    type: walletActions.INITIAL_CHOOSE_WALLET,
  };
}
export function initialCheckNet() {
  return {
    type: walletActions.INITIAL_CHECK_NET,
  };
}
export function initialLoadingDialog() {
  return {
    type: walletActions.INITIAL_LOADINGDIALOG,
  };
}
export function initialQuitDialog() {
  return {
    type: walletActions.INITIAL_QUITDIALOG,
  };
}
export function initialTedianDialog() {
  return {
    type: walletActions.INITIAL_TEDIANDIALOG,
  };
}
